<template>
  <div :id="id" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
            <div class="text-3xl mt-5">Are you sure?</div>
            <div class="text-gray-600 mt-2">{{ question }} <br /></div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
            >
              Cancel
            </button>
            <slot name="delete-button"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
export default {
  name: "ModalDialog",
  props: {
    question: {
      type: String,
      default: "Do you really want to delete these records?",
    },
    id: {
      type: String,
      default: "delete-modal",
    },
  },
};
</script>

<style scoped></style>
